.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh; /* Set the container to fill the full height of the viewport */
    
  }
  
  .centered-div {
    padding: 20px;
    border: 0px solid #ccc;
    text-align: center;
    width: 95%;
  }