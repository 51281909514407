
.App {
  text-align: center;
  height: 75vh;
  background-color: var(--background-color);
 
}

body{

  background-color: var(--background-color);
  color: var(--colour);

}

p {
  font-size: .75em;
}

h2 {

  font-size: .90em;
  color: var(--titles);
  
}

h3:before,
h3::after
{ content:  ' ' !important;
  width: 30px !important;
  border-bottom: 1px solid !important;
  display: inline-block !important;
  transform: translate(0, -5px) !important;
  margin: 0 14px !important;
  letter-spacing: 0.05em;
  color: var(--jd-green);
}

h1 {

  font-size: .90em;
  color: var(--titles);
}

h3 {

  font-size: 1.0em;
  color: var(--jd-red);
}

b {

  font-size: .90em;
  color: var(--titles);
}

.form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 3px;
}

.form-group {
  margin-bottom: 20px;
}

input[type='password'],
input[type='email'],
input[type='TEL'],
input[type='number'],
input[type='text'],
input[type='email'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

label{

color: var(--nav-link);
margin-bottom: 0.5rem;

}



/* .nav-link{

  color: var(--nav-link);

}

.nav-link:hover{

  color: var(--colour-white);

} */


a {
  color: var(--jd-green) !important;
  text-decoration: none; /* Optional: Remove underline */
}

a:hover {
  color: var(--jd-red) !important; /* Change to your desired hover color */
  text-decoration: none; /* Optional: Underline on hover */
}

.nav-link {
  color: var(--jd-green);
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition */
}

.nav-link:hover {
  color: var(--jd-red) /* Change to your preferred rollover color */
}

.warning{

  color: #fa255e;
}
.fa-times{

  color: var(--jd-green);
}

.fa-bars{

  color: var(--jd-green);
}

button {
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 0.1em .3em;
  font-size: 1.2em;
  font-weight: 500;
  font-family: inherit;
  background-color: var(--jd-green);
  cursor: pointer;
  transition: border-color 0.25s;
  color: var(--background-color);
}

.button2 {
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 0.1em .3em;
  font-size: 1.6em;
  font-weight: 500;
  font-family: inherit;
  background-color: var(--titles);
  cursor: pointer;
  transition: border-color 0.25s;
  color: var(--background-color);
}

button:hover {
  /* border-color: #646cff; */
  background-color: var(--jd-red);
}

.container_2 {
  padding: 20px;
  background-color: var(--background-color);
  
  border-radius: 5px;
  max-width: 800px;
  margin: 20px auto;
  width: 90%;
  
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;

}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.content {
  text-align: center;
  padding: 20px;
  border: 0px solid #ccc;
}

.form-container {
  display: grid;
  place-items: center;
  height: 25vh;
}

form {
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 900px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: var(--background-color);
}

.form-group {
  display: flex;
  flex-direction: column;
}

.aa{

  text-decoration: none;
  color: #fa255e;
  background-color: #fa255e;
  padding: .3rem, .3rem !important;

}

table{

    color: var(--colour-white);
   
    padding: .3rem .3rem !important;

}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 0px solid #ddd;
}

.tableblack{

  color: var(--background-color);

}

p{

color: #ccc;

}


.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.password-input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;  /* Extra padding for the icon space */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.icon-button svg {
  font-size: 20px;
  color: gray;
}

.styled-link {
  color: white;          /* Set the link color */
  text-decoration: none;   /* Remove underline */
  font-weight: bold;       /* Make the text bold */
  padding: 5px 10px;       /* Add some padding */
  border-radius: 5px;      /* Rounded corners */
  transition: color 0.3s ease, background-color 0.3s ease;  /* Smooth transition effect */
}

.styled-link:hover {
  color: white;             /* Change the text color on hover */
  background-color: #4CAF50; /* Add a background color on hover */
}


.bannerStyle {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

input:-webkit-autofill {
  background-color: yellow; /* Change color to see if autofilled */
}

.no-border {
  border: 0px; /* Remove border */
}
/* .const buttonStyle{
  background-color: '#4CAF50',
  color: 'white',
  border: 'none',
  padding: '2px 4px',
  cursor: 'pointer',
  marginLeft: '5px',
}; */