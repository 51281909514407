:root {
  --titles: #fa255e;
  --header: #888473;
  --secondButton:#bcbcf3;
  
  --background-color: #ffffff;
  --colour-white: #1c1C1C;
  --nav-link-hover: #a9233e;
  --nav-link: #1c1C1C;
  --jd-green: #046839;
  --jd-red: #a9223f;
  
}

.paddingTop{

padding-top: 10px;

}

.Slide {
    text-align: center;
    /* height: 100vh; */
    background-color:var(--background-color);
    color: white;
  }

.div{


    background-color:var(--background-color);
}