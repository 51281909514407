.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3%;
  background-color: #fff; /* Darker background for contrast */
  color: #fff;
  text-decoration: none;
}

.logo {
  display: flex;
}

.responsive-logo {
  height: 40px;
}

.nav-items {
  display: flex;
  gap: 20px;
  margin-left: auto; /* Pushes the nav items to the right */
}


.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.fas {
  color: #fff;
}

/* Mobile styles */
@media (max-width: 768px) {
  .nav-items {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff; /* Matches navbar background */
    width: 35%;
    text-align: right;
    padding: 3%;
    text-decoration: none;
  }

  .nav-items.open {
    display: flex;
  }

  .nav-link {
    padding: 10px 0;
    border-top: 1px solid #444; /* Slight border for separation */
  }

  .hamburger {
    display: block;
  }
}
